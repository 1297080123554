import React, { useEffect, useState } from "react";
import tickIcon from "../../assets/img/tick-icon.png";
import chevronUp from "../../assets/img/chevron_up.webp";
import chevronDown from "../../assets/img/chevron_down.webp";
import { HashLink } from "react-router-hash-link";

function Mitgliedschaft() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const [sub1Dropped, setSub1Dropped] = useState(false);

  const handleDropdownSub1 = () => {
    setSub1Dropped(!sub1Dropped);
  };

  const [sub2Dropped, setSub2Dropped] = useState(false);

  const handleDropdownSub2 = () => {
    setSub2Dropped(!sub2Dropped);
  };

  return (
    <div className="subscription-container">
      <div>
        <div className="subscription-header">Unsere Mitgliedschaften</div>
        <div className="subscription-list">
          <div className="subscription-item">
            <div className="subscription-item-heading">
              <div className="subscription-item-title">
                Gerätetraining und Kurse
              </div>
              <div className="subscription-item-price">
                <div className="subscription-item-price-tag">24,00 €</div>
                <div className="subscription-item-price-period">/MONAT</div>
              </div>
            </div>
            <div className="subscription-item-info">
              12 Monate Mindestlaufzeit
            </div>
            <div className="subscription-item-control">
              <div
                className="subscription-item-details-btn"
                onClick={handleDropdownSub1}
              >
                <div className="subscription-item-details-btn-text">
                  {sub1Dropped ? "Verbergen" : "Details"}
                </div>
                <img
                  className="subscription-item-details-btn-icon"
                  src={sub1Dropped ? chevronUp : chevronDown}
                  alt="chevron"
                ></img>
              </div>
              <HashLink
                to="/mitgliedschaft-beantragen?subscription=1"
                className="subscription-item-purchase-btn"
              >
                Wählen
              </HashLink>
            </div>
            <div
              className="subscription-item-dropdown"
              data-type={sub1Dropped ? "dropped" : ""}
            >
              <div className="horizontal-line"></div>
              <div className="subscription-item-detail-list">
                <div className="subscription-item-detail-item">
                  <img
                    className="subscription-item-detail-item-icon"
                    src={tickIcon}
                    alt="tick"
                  ></img>
                  <div className="subscription-item-detail-item-text">
                    Anmeldegebühr 10,00 €
                  </div>
                </div>
                <div className="subscription-item-detail-item">
                  <img
                    className="subscription-item-detail-item-icon"
                    src={tickIcon}
                    alt="tick"
                  ></img>
                  <div className="subscription-item-detail-item-text">
                    Kaution Chipkarte 10,00 €
                  </div>
                </div>
                <div className="subscription-item-detail-item">
                  <img
                    className="subscription-item-detail-item-icon"
                    src={tickIcon}
                    alt="tick"
                  ></img>
                  <div className="subscription-item-detail-item-text">
                    Alle Geräte im Studio inbegriffen
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="subscription-item">
            <div className="subscription-item-heading">
              <div className="subscription-item-title">Nur Gerätetraining</div>
              <div className="subscription-item-price">
                <div className="subscription-item-price-tag">20,00 €</div>
                <div className="subscription-item-price-period">/MONAT</div>
              </div>
            </div>
            <div className="subscription-item-info">
              12 Monate Mindestlaufzeit
            </div>
            <div className="subscription-item-control">
              <div
                className="subscription-item-details-btn"
                onClick={handleDropdownSub2}
              >
                <div className="subscription-item-details-btn-text">
                  {sub2Dropped ? "Verbergen" : "Details"}
                </div>
                <img
                  className="subscription-item-details-btn-icon"
                  src={sub2Dropped ? chevronUp : chevronDown}
                  alt="chevron"
                ></img>
              </div>
              <HashLink
                to="/mitgliedschaft-beantragen?subscription=2"
                className="subscription-item-purchase-btn"
              >
                Wählen
              </HashLink>
            </div>
            <div
              className="subscription-item-dropdown"
              data-type={sub2Dropped ? "dropped" : ""}
            >
              <div className="horizontal-line"></div>
              <div className="subscription-item-detail-list">
                <div className="subscription-item-detail-item">
                  <img
                    className="subscription-item-detail-item-icon"
                    src={tickIcon}
                    alt="tick"
                  ></img>
                  <div className="subscription-item-detail-item-text">
                    Anmeldegebühr 10,00 €
                  </div>
                </div>
                <div className="subscription-item-detail-item">
                  <img
                    className="subscription-item-detail-item-icon"
                    src={tickIcon}
                    alt="tick"
                  ></img>
                  <div className="subscription-item-detail-item-text">
                    Kaution Chipkarte 10,00 €
                  </div>
                </div>
                <div className="subscription-item-detail-item">
                  <img
                    className="subscription-item-detail-item-icon"
                    src={tickIcon}
                    alt="tick"
                  ></img>
                  <div className="subscription-item-detail-item-text">
                    Alle Geräte im Studio inbegriffen
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="subscription-header">Weitere Angebote.</div>
        <div className="product-container" data-type="lower">
          <div className="product-header">10er Karte*</div>
          <div className="product-item">
            <div className="product-item-header">Gerätetraining</div>
            <div className="product-item-price">
              60,00 € [+10,00€ Kaution Chipkarte]
            </div>
          </div>
          <div className="product-item">
            <div className="product-item-header">Offene Kurse</div>
            <div className="product-item-price">80,00 €</div>
          </div>
        </div>
        <div className="subscription-info">
          * 10er Karten können nur Vorort erworben werden.
        </div>
      </div>
    </div>
  );
}

export default Mitgliedschaft;
