import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import kurs1 from "../../../assets/img/AdobeStock_279455077.webp";

function Rehabilitationsport() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  return (
    <div className="kurspage-container">
      <div className="kurspage-header">Rehabilitationssport</div>

      {isMobile ? (
        <div className="even-areas">
          <div className="kurspage-paragraph">
            <p>
              Rehabilitationssport zielt sowohl auf die Steigerung von Kraft und
              Ausdauer als auch auf die Verbesserung der Koordination und
              Beweglichkeit ab und bedarf einer ärztlichen Verordnung sowie der
              Genehmigung Ihrer Krankenkasse.
            </p>
          </div>
          <img
            className="kurspage-img"
            src={kurs1}
            alt="Bild Rehabilitationssport"
            data-type="marginUp"
          ></img>
        </div>
      ) : (
        <div className="kurspage-text-img">
          <img
            className="kurspage-img"
            src={kurs1}
            alt="Bild Rehabilitationssport"
            data-type="marginUp"
          ></img>
          <div className="kurspage-paragraph">
            <p>
              Rehabilitationssport zielt sowohl auf die Steigerung von Kraft und
              Ausdauer als auch auf die Verbesserung der Koordination und
              Beweglichkeit ab und bedarf einer ärztlichen Verordnung sowie der
              Genehmigung Ihrer Krankenkasse.
            </p>
          </div>
        </div>
      )}
      <div className="kurspage-paragraph-wrapper">
        <div className="kurspage-sub-header">
          Wie können Sie daran teilnehmen?
        </div>
        <div className="kurspage-paragraph">
          <p>
            Um am Rehabilitationssport teilnehmen zu können, benötigen Sie eine
            Verordnung, welche Sie bei Ihrem Hausarzt oder Facharzt bekommen.
            Sprechen Sie Ihren Arzt einfach darauf an. Die meisten Ärzte sind
            über diesen Sport sehr gut informiert und verordnen diesen wenn die
            Bedürftigkeit vorliegt.
          </p>
        </div>
      </div>
      <div className="kurspage-paragraph-wrapper">
        <div className="kurspage-sub-header">
          Für wie lange gilt die Verordnung?
        </div>
        <div className="kurspage-paragraph">
          <p>
            Die Verordnungsdauer beim Rehabilitationssport umfasst in der Regel
            50 Übungseinheiten bzw. einen Zeitraum von 18 Monaten und beginnt ab
            der Genehmigung der Krankenkasse. Der Herzsport bildet eine Ausnahme
            und wird über 90 Übungseinheiten bzw. 24 Monate verordnet.
          </p>
        </div>
      </div>
      <div className="kurspage-paragraph-wrapper">
        <div className="kurspage-sub-header">Unser Tipp:</div>
        <div className="kurspage-paragraph">
          <p>
            Neben der Verordnung über Rehabilitationssport können Sie zusätzlich
            Mitglied im Gesundheits-sportverein Epikur e. V. werden.
          </p>
          <p>
            Durch eine Mitgliedschaft im Verein stehen Ihnen zusätzlich
            Sportgeräte zur Verfügung, welche Sie neben dem
            Rehabilitationssportangebot täglich nutzen können. Dies gilt auch
            für den Zeitbereich nach Ihrer Verordnung.
          </p>
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="kurspage-paragraph-wrapper">
        <div className="kurspage-sub-header">
          Rehabilitationssport in Herzgruppen
        </div>
        <div className="kurspage-paragraph">
          <p>
            Herzsport ist eine Rehabilitationsmaßnahme für Patienten mit
            kardialen Erkrankungen. Nach Abschluss der kardiologischen
            Behandlung wird zur Wiederherstellung bzw. Verbesserung der durch
            die Erkrankung reduzierten körperlichen Fähigkeiten der Herzsport
            ärztlich verordnet. Im Herzsport, in der Gruppe Gleichgesinnter,
            bauen wir schrittweise wieder mehr Selbstvertrauen in die eigene
            Leistungsfähigkeit auf. Sport in der Herzgruppe fördert die
            Ausdauerfähigkeit, hilft falsche Schonung abzubauen und bringt
            Lebensfreude sowie Geselligkeit.
          </p>
          <p>
            Um an diesem Kurs teilnehmen zu können, benötigen Sie neben der
            ärztlichen Verordnung über den Rehabilitationssport in Herzgruppen,
            ein Belastungs-EKG welches nicht älter als 3 Monate ist.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Rehabilitationsport;
