import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";
import ImageGallery from "../components/ImageGallery";
import News from "../components/News";
import Services from "../components/Services";

function Main() {
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });
  const imgList = [
    "AdobeStock_158886042.webp",
    "AdobeStock_536581094.webp",
    "AdobeStock_177817667.webp",
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <section id="home">
        {isMobile ? (
          <ImageGallery imgList={imgList} mobile={true}></ImageGallery>
        ) : (
          <ImageGallery imgList={imgList} mobile={false}></ImageGallery>
        )}
      </section>

      <section id="welcome">
        <div className="padding-block-700 padding-inline welcome-wrapper">
          {isMobile ? (
            <span>
              <p className="welcome-header">Herzlich Willkommen</p>
              <p className="welcome-header">
                beim Gesundheitssportverein Epikur e. V.
              </p>
            </span>
          ) : (
            <p className="welcome-header">
              Herzlich Willkommen beim Gesundheitssportverein Epikur e. V.
            </p>
          )}

          <p className="welcome-paragraph">
            Unser Verein wurde am 15.11.2006 gegründet und ist ein eingetragener
            gemeinnütziger Verein für Präventions-, Rehabilitations-,
            Behinderten- und Breitensport.
          </p>
          <p className="welcome-paragraph" data-type="team-list">
            Unser Team besteht aus:
          </p>
          <ul className="welcome-team-list">
            <li>
              <div className="welcome-team-list-item">
                <p className="welcome-team-list-name">Jana Schmidt</p>
                <p className="welcome-team-list-job">
                  Übungsleiterin / Lehrerin für Fitness
                </p>
              </div>
            </li>
            <li>
              <div className="welcome-team-list-item">
                <p className="welcome-team-list-name">Kathrin Genilke</p>
                <p className="welcome-team-list-job">Übungsleiterin</p>
              </div>
            </li>
            <li>
              <div className="welcome-team-list-item">
                <p className="welcome-team-list-name">Julia Dietrich</p>
                <p className="welcome-team-list-job">
                  Übungsleiterin / Sport- und Fitnesskauffrau
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section id="aktuelles">
        <News isMobile={isMobile} />
      </section>

      <section id="leistungen">
        <Services isMobile={isMobile} />
      </section>

      <section id="location">
        <div className="padding-block-700 padding-inline">
          <div className="fs-primary-heading">Hier finden Sie uns.</div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d521.9691327386446!2d13.390044779556444!3d51.51472723011849!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a79204753afea3%3A0xaf52177bee90df84!2sGesundheitssportverein%20Epikur%20e.%20V.!5e0!3m2!1sde!2sde!4v1707225174014!5m2!1sde!2sde"
            allowfullscreen="false"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </>
  );
}

export default Main;
