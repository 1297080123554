import React, { useEffect, useState, useCallback, useRef } from "react";
import chevronRight from "../../assets/img/chevron_right_black.webp";
import chevronLeft from "../../assets/img/chevron_left_black .webp";

function MobileImgGallery({ Items, maxItems, extendedControlls }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeAutoplay, setActiveAutoplay] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const autoplayRef = useRef();

  const settings = {
    maxItems: maxItems,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const goTo = useCallback(
    (index) => {
      if (!isAnimating) {
        setCurrentIndex(index);
        setIsAnimating(true);

        setTimeout(() => {
          setIsAnimating(false);
        }, settings.speed);
      }
    },
    [isAnimating, currentIndex]
  );

  /* Go Next */
  const goNext = () => {
    goTo(currentIndex >= settings.maxItems - 1 ? 0 : currentIndex + 1);
  };

  /* Go Prev */
  const goPrev = () => {
    goTo(currentIndex <= 0 ? settings.maxItems - 1 : currentIndex - 1);
  };

  /* Play Timer */
  const playTimer = () => {
    setActiveAutoplay(true);
  };

  /* Pause Timer */
  const pauseTimer = () => {
    setActiveAutoplay(false);
  };

  useEffect(() => {
    if (settings.autoplay && activeAutoplay) {
      clearTimeout(autoplayRef.current);
      autoplayRef.current = setTimeout(() => {
        goNext();
      }, settings.autoplaySpeed);
    }
  }, [currentIndex, activeAutoplay, isAnimating]);

  const dots = () => {
    const totalItems = Array.apply(null, Array(settings.maxItems));
    const dotsButtons = totalItems.map((item, index) => {
      return (
        <div
          className={
            index === currentIndex
              ? "card-slider-pagination-item card-slider-pagination-item-active"
              : "card-slider-pagination-item"
          }
          key={index.toString()}
          onMouseEnter={pauseTimer}
          onMouseLeave={playTimer}
          onClick={() => goTo(index)}
        ></div>
      );
    });
    return (
      <div className="card-slider-pagination-wrapper">
        <div className="card-slider-pagination">{dotsButtons}</div>
      </div>
    );
  };

  const controls = () => {
    if (extendedControlls) {
      return (
        <div className="card-slider-controller">
          <div
            className="card-slider-controller-item"
            onClick={(e) => goPrev()}
          >
            <div className="card-slider-controller-chevron">
              <img src={chevronLeft} alt="chevron-links"></img>
            </div>
          </div>
          <div></div>
          <div
            className="card-slider-controller-item"
            onClick={(e) => goNext()}
          >
            <div className="card-slider-controller-chevron">
              <img src={chevronRight} alt="chevron-rechts"></img>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const slide = (index) => {
    var currentItem = require("../../assets/img/" + Items[index]);

    return (
      <div className="card-link-list">
        <div className="card">
          <img src={currentItem} alt="card-img"></img>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="card-slider">
        {dots()}
        {controls()}
        {slide(currentIndex)}
      </div>
    </>
  );
}

export default MobileImgGallery;
