import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import downloadIcon from "../../assets/img/download-icon.webp";
import pdfAA from "../../assets/pdf/Aufnahmeantrag.pdf";
import pdfBO from "../../assets/pdf/Beitragsordnung.pdf";
import pdfS from "../../assets/pdf/Satzung.pdf";

function SubscriptionForm({ AfterSubmitAction, defaultSubType }) {
  const [showErrorFile1Type, setShowErrorFile1Type] = useState(false);
  const [showErrorFile2Type, setShowErrorFile2Type] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    mail: "",
    subscriptionType: "",
    file1: "",
    file2: "",
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
    const subscriptionDropdown = document.getElementById("subscriptionType");
    subscriptionDropdown.value = defaultSubType;
  }, []);

  const sendJobRequest = async () => {
    const form = document.getElementById("subscription-form");

    const _formData = new FormData(form);
    const formValues = [..._formData.entries()];

    const file1Type = formValues[5][1].type;
    const file2Type = formValues[6][1].type;

    if (file1Type === "application/pdf") {
      setShowErrorFile1Type(false);
    } else {
      setShowErrorFile1Type(true);
      return;
    }

    if (file2Type === "application/pdf") {
      setShowErrorFile2Type(false);
    } else {
      setShowErrorFile2Type(true);
      return;
    }

    axios
      .post("server/sendSubscriptionMail.php", _formData)
      .then(function (response) {
        AfterSubmitAction(true);
      });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    sendJobRequest();
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <form id="subscription-form" data-type="contact" onSubmit={onSubmitForm}>
      <div className="form-control">
        <label htmlFor="firstName" className="form-label">
          * Vorname
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          type="text"
          name="firstName"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="lastName" className="form-label">
          * Name
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          type="text"
          name="lastName"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="phone" className="form-label">
          * Telefon
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          type="tel"
          name="phone"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="mail" className="form-label">
          * E-Mail
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          type="mail"
          name="mail"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="subscriptionType" className="form-label">
          * Welche Mitgliedschaft möchten Sie erwerben
        </label>
        <select
          className="form-input"
          onChange={handleChange}
          name="subscriptionType"
          id="subscriptionType"
          required
        >
          <option value="">Bitte auswählen</option>
          <option value="Gerätetraining und Kurse">
            Gerätetraining und Kurse
          </option>
          <option value="Gerätetraining">Gerätetraining</option>
        </select>
      </div>

      <div className="form-download">
        <div className="form-download-header">
          Benötigte Dokumente herunterladen
        </div>
        <div className="form-download-wrapper">
          <HashLink
            className="form-download-container"
            to={pdfAA}
            target="_blank"
            rel="noreferrer noopener"
          >
            <p>Aufnahmeantrag</p>
            <img src={downloadIcon} alt="Herunterladen"></img>
          </HashLink>

          <HashLink
            className="form-download-container"
            to={pdfBO}
            target="_blank"
            rel="noreferrer noopener"
          >
            <p>Beitragsordnung</p>
            <img src={downloadIcon} alt="Herunterladen"></img>
          </HashLink>

          <HashLink
            className="form-download-container"
            to={pdfS}
            target="_blank"
            rel="noreferrer noopener"
          >
            <p>Satzung</p>
            <img src={downloadIcon} alt="Herunterladen"></img>
          </HashLink>
        </div>
        <div className="form-control">
          <label htmlFor="file1" className="form-label">
            * Aufnahmeantrag als PDF anhängen
          </label>
          <input
            type="file"
            id="file1"
            name="file1"
            required
            onChange={handleChange}
          />
          {showErrorFile1Type ? (
            <p className="form-control-error-msg">
              Falscher Dateityp ausgewählt
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="form-control">
          <label htmlFor="file2" className="form-label">
            * Beitragsordnung als PDF anhängen
          </label>
          <input
            type="file"
            id="file2"
            name="file2"
            required
            onChange={handleChange}
          />
          {showErrorFile2Type ? (
            <p className="form-control-error-msg">
              Falscher Dateityp ausgewählt
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="form-control">
        <span className="form-control-info">
          <input
            type="checkbox"
            placeholder="Datenschutz"
            required
            name="datenschutz"
          />
          <span>
            Ich willige in die in der&nbsp;
            <HashLink to="/datenschutz">Datenschutzerklärung</HashLink>&nbsp;
            beschriebene Verarbeitung meiner Daten ein:&nbsp;
            <span className="form-control-info-hl">*</span>
          </span>
        </span>
      </div>

      <button type="submit" className="btn">
        Absenden
      </button>
    </form>
  );
}

export default SubscriptionForm;
