import React, { useEffect } from "react";
import RentnerFit from "../../../assets/img/AdobeStock_233158793.webp";
import RuckenFit from "../../../assets/img/AdobeStock_277230029.webp";
import PowerWorkout from "../../../assets/img/AdobeStock_614649856.webp";
import Pilates from "../../../assets/img/AdobeStock_487684910.webp";
import FitVonKopfBisFuss from "../../../assets/img/AdobeStock_536581094.webp";
import HathaYoga from "../../../assets/img/AdobeStock_657314659.webp";

function Gesundheitskurse() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div className="kurspage-container">
      <div className="kurspage-header">Gesundheitskurse</div>
      <div className="kurspage-list">
        <div className="kurspage-list-item">
          <img
            src={RentnerFit}
            alt="Bild RentnerFit"
            className="kurspage-list-item-img"
          ></img>
          <div className="kurspage-list-item-header">Senioren Fit</div>
          <div className="kurspage-list-item-description">
            Mix aus Gleichgewichts-, Ausdauer-, Kraftausdauer- und Krafttraining
            für alle Junggebliebenen und die die es bleiben wollen.
          </div>
        </div>
        <div className="kurspage-list-item">
          <img
            src={RuckenFit}
            alt="Bild RückenFit"
            className="kurspage-list-item-img"
          ></img>
          <div className="kurspage-list-item-header">Rücken Fit</div>
          <div className="kurspage-list-item-description">
            Das Training bezieht sich mit seinen Inhalten intensiv auf die
            Systemeinheit Rücken.
          </div>
        </div>
        <div className="kurspage-list-item">
          <img
            src={PowerWorkout}
            alt="Bild Power Workout"
            className="kurspage-list-item-img"
          ></img>
          <div className="kurspage-list-item-header">Bauch Beine Po</div>
          <div className="kurspage-list-item-description">
            Dieser spezielle Kurs bietet ein gezieltes Training für die
            „sogenannten „ Problemzonen Bauch, Beine, Po, um eine straffe
            Körpermitte zu formen. Ein starker Rumpf und trainierte Beine tragen
            zu einer besseren Körperhaltung und Körperspannung bei.
            Rückenschmerzen werden vorgebeugt und des Weiteren “ordentlich“
            Kalorien verbrannt.
          </div>
        </div>
        <div className="kurspage-list-item">
          <img
            src={Pilates}
            alt="Bild Pilates"
            className="kurspage-list-item-img"
          ></img>
          <div className="kurspage-list-item-header">Pilates</div>
          <div className="kurspage-list-item-description">
            Ganzheitliches Krafttraining der Beckenboden- und tiefen
            Rumpfmuskulatur. Mit gezielten Übungen für die sogenannte
            Stützmuskulatur wird neben der Kondition und der
            Bewegungskoordination vor allem auch die Körperhaltung verbessert.
          </div>
        </div>
        <div className="kurspage-list-item">
          <img
            src={FitVonKopfBisFuss}
            alt="Bild Fit von Kopf bis Fuß"
            className="kurspage-list-item-img"
          ></img>
          <div className="kurspage-list-item-header">Fit von Kopf bis Fuß</div>
          <div className="kurspage-list-item-description">
            Abwechslungsreiches Ganzkörpertraining mit Hilfe von
            Kleinsportgeräten.
          </div>
        </div>
        <div className="kurspage-list-item">
          <img
            src={HathaYoga}
            alt="Bild Hatha Yoga"
            className="kurspage-list-item-img"
          ></img>
          <div className="kurspage-list-item-header">Hatha Yoga</div>
          <div className="kurspage-list-item-description">
            Es ist eine Form des Yoga, bei der das Gleichgewicht zwischen Körper
            und Geist angestrebt wird. Körperübungen, Atemübungen und
            Entspannungsübungen wikren ausgleichend auf unser Nervensystem.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gesundheitskurse;
