import React, { useEffect } from "react";
import kurs1 from "../../assets/img/AdobeStock_279455077.webp";
import kurs2 from "../../assets/img/AdobeStock_177817667.webp";
import kurs3 from "../../assets/img/AdobeStock_192189854.webp";
import { HashLink } from "react-router-hash-link";
import Kursplan from "../Kurs/Kursplan";
import KursReminder from "../Kurs/KursReminder";
import { format } from "date-fns";
import { de } from "date-fns/locale";

function Kurse() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const curMonth = format(new Date(), "MMMM", { locale: de });

  return (
    <>
      <div className="kurspage-container padding-inline">
        <div className="kurs-group-container">
          <div className="kurs-group-header">Unsere Kursangebote</div>
          <div className="kurs-group">
            <HashLink className="kurs-group-item" to={"/rehabilitationssport"}>
              <img src={kurs1} alt="kurs1"></img>
              <div className="kurs-group-content">
                <div className="kurs-group-item-header">
                  Rehabilitationssport
                </div>
                <div className="kurs-group-item-link">
                  Weitere Informationen
                </div>
              </div>
            </HashLink>
            <HashLink className="kurs-group-item" to={"/gesundheitskurse"}>
              <img src={kurs2} alt="kurs2"></img>
              <div className="kurs-group-content">
                <div className="kurs-group-item-header">Gesundheitskurse</div>
                <div className="kurs-group-item-link">
                  Weitere Informationen
                </div>
              </div>
            </HashLink>
            <HashLink className="kurs-group-item" to={"/geraetezirkel"}>
              <img src={kurs3} alt="kurs3"></img>
              <div className="kurs-group-content">
                <div className="kurs-group-item-header">Gerätezirkel</div>
                <div className="kurs-group-item-link">
                  Weitere Informationen
                </div>
              </div>
            </HashLink>
          </div>
        </div>
      </div>
      <div className="bwt-bg-wrapper">
        <KursReminder />
      </div>
      <div className="kurs-plan-container" id="kursplan">
        <div className="kurs-plan-header">Unser Kursplan für {curMonth}</div>
        <Kursplan />
      </div>
    </>
  );
}

export default Kurse;
