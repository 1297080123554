import React from "react";
import { HashLink } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";
import img from "../../assets/img/AdobeStock_536581094.webp";

function KursReminder() {
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  const KursReminderContent = () => {
    return (
      <>
        <div className="kurs-reminder-header">
          Warum unsere Fitnessprogramme perfekt zu Ihnen passen.
        </div>
        <div className="kurs-reminder-text">
          Jeder Körper braucht Abwechslung, damit er sich weiterentwickelt.
          Dafür sind unsere Kurse die perfekte Alternative zum herkömmlichen
          Gerätetraining.
        </div>
        <div className="even-areas">
          <HashLink className="btn" data-type="inverted" to={"/kurse#kursplan"}>
            Unser Kursplan
          </HashLink>
          <HashLink className="btn" to={"/mitgliedschaft"}>
            Jetzt Mitglied werden
          </HashLink>
        </div>
      </>
    );
  };

  return (
    <>
      {isMobile ? (
        <div className="bwt-fg-wrapper kurs-reminder-container">
          {KursReminderContent()}
        </div>
      ) : (
        <div className="bwt-fg-wrapper">
          <div className="kurs-reminder-container">
            <img
              className="kurs-reminder-img"
              src={img}
              alt="ReminderImage"
            ></img>
            <div className="kurs-reminder-content">{KursReminderContent()}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default KursReminder;
