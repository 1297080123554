import React from "react";
import { HashLink } from "react-router-hash-link";
import img from "../assets/img/AdobeStock_148812895.webp";

function Services({ isMobile }) {
  const ServiceListComponent = () => {
    return (
      <div className="service-list">
        <div className="service-item">
          <div className="service-item-img-wrapper"></div>
          <div className="service-item-content">
            <div className="service-item-content-wrapper">
              <div className="service-item-header">Rehabilitationssport</div>
              <div className="service-item-preview">
                in den Bereichen Orthopädie und Innere Medizin (Herzsport)
              </div>
            </div>
            <div className="service-item-nav">
              <HashLink
                className="service-item-link"
                to={"/rehabilitationssport"}
              >
                Erfahren Sie mehr
              </HashLink>
            </div>
          </div>
        </div>

        <div className="service-item">
          <div className="service-item-img-wrapper"></div>
          <div className="service-item-content">
            <div className="service-item-content-wrapper">
              <div className="service-item-header">Gesundheitskurse</div>
              <div className="service-item-preview">
                wie z.B. Rücken- Fit, Pilates, Fit von Kopf bis Fuß
              </div>
            </div>
            <div className="service-item-nav">
              <HashLink className="service-item-link" to={"/gesundheitskurse"}>
                Erfahren Sie mehr
              </HashLink>
            </div>
          </div>
        </div>

        <div className="service-item">
          <div className="service-item-img-wrapper"></div>
          <div className="service-item-content">
            <div className="service-item-content-wrapper">
              <div className="service-item-header">Gerätezirkel</div>
            </div>
            <div className="service-item-nav">
              <HashLink className="service-item-link" to={"/geraetezirkel"}>
                Erfahren Sie mehr
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isMobile ? (
        <div className="bwt-bg-wrapper">
          <div className="bwt-fg-wrapper padding-block-500 padding-inline">
            <div className="service-wrapper">
              <div className="service-heading">
                Alles drin für ihre Gesundheit.
              </div>
              {ServiceListComponent()}
              <HashLink className="btn" to={"/mitgliedschaft"}>
                Jetzt Mitglied werden
              </HashLink>
            </div>
          </div>
        </div>
      ) : (
        <div className="bwt-bg-wrapper">
          <div className="bwt-fg-wrapper">
            <div className="padding-inline">
              <div className="service-even-areas">
                <img className="service-img" src={img} alt="Service Bild"></img>
                <div className="service-wrapper padding-block-500 ">
                  <div className="service-heading">
                    Alles drin für ihre Gesundheit.
                  </div>
                  {ServiceListComponent()}
                  <HashLink className="btn" to={"/mitgliedschaft"}>
                    Jetzt Mitglied werden
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Services;
