import React from "react";
import { HashLink } from "react-router-hash-link";
import openingTimes from "../data/openingTimes.json";
import { useMediaQuery } from "react-responsive";

function Footer() {
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  const FooterOfficeTimes = () => {
    return (
      <div className="primary-footer-officeTimes" id="oeffnungszeiten">
        <div className="primary-footer-officeTimes-header">Öffnungszeiten</div>
        <div className="primary-footer-officeTimes-item">
          <p>Montag:</p>
          <p>{openingTimes["Mo"]}</p>
        </div>
        <div className="primary-footer-officeTimes-item">
          <p>Dienstag:</p>
          <p>{openingTimes["Di"]}</p>
        </div>
        <div className="primary-footer-officeTimes-item">
          <p>Mittwoch:</p>
          <p>{openingTimes["Mi"]}</p>
        </div>
        <div className="primary-footer-officeTimes-item">
          <p>Donnerstag:</p>
          <p>{openingTimes["Do"]}</p>
        </div>
        <div className="primary-footer-officeTimes-item">
          <p>Freitag:</p>
          <p>{openingTimes["Fr"]}</p>
        </div>
        <div className="primary-footer-officeTimes-item">
          <p>Samstag:</p>
          <p>{openingTimes["Sa"]}</p>
        </div>
        <div className="primary-footer-officeTimes-item">
          <p>Sonntag:</p>
          <p>{openingTimes["So"]}</p>
        </div>
      </div>
    );
  };

  const FooterNav = () => {
    return (
      <div className="primary-footer-nav">
        <div className="footer-nav-group">
          <HashLink to="/impressum">Impressum</HashLink>
          <HashLink to="/datenschutz">Datenschutz</HashLink>
        </div>
        <div className="footer-nav-group">
          <HashLink to="/kontakt">Kontakt</HashLink>
          <HashLink to="/mitgliedschaft">Mitgliedschaft</HashLink>
        </div>
      </div>
    );
  };

  const FooterCopyRight = () => {
    return (
      <div className="primary-footer-links">
        <p>© 2024 GSV Epikur</p>
      </div>
    );
  };

  return (
    <footer className="bwt-bg-wrapper">
      {isMobile ? (
        <div className="bwt-fg-wrapper">
          {FooterOfficeTimes()}
          {FooterNav()}
          {FooterCopyRight()}
        </div>
      ) : (
        <div className="bwt-fg-wrapper">
          <div className="primary-footer-wrapper">
            {FooterOfficeTimes()}
            <div className="primary-footer-links-wrapper">
              {FooterNav()}
              {FooterCopyRight()}
            </div>
          </div>
        </div>
      )}
    </footer>
  );
}

export default Footer;
