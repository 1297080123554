import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import geraeteZirkelImg from "../../../assets/img/DSC7845.webp";

function Geraetezirkel() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  return (
    <div className="kurspage-container">
      <div className="kurspage-header">Gerätezirkel</div>

      <div className={isMobile ? "even-areas" : "kurspage-text-img"}>
        <img
          className="kurspage-img"
          src={geraeteZirkelImg}
          alt="Bild Gerätezirkel"
          data-type="marginDown"
        ></img>
        <div className="kurspage-paragraph">
          <p>
            Für ein gezieltes und ausgewogenes Gerätetraining bietet unsere
            Trainingsfläche mit zahlreichen modernen Ausdauer- und
            Krafttrainingsgeräten für alle Trainierenden die optimalen
            Voraussetzungen.
          </p>
          <p>
            Mit Hilfe eines Kraftzirkels der Firma excio trainieren die
            Patienten und Sportler auf hydraulikbasierten Krafttrainingsgeräten,
            die über eine personalisierte Chipkarte bedient werden können.
            Weiterhin erleichtert eine Memory-Funktion das Trainieren enorm,
            indem individuell eingestellte Werte problemlos gespeichert und beim
            nächsten Training wieder abgerufen werden können. Mit ergonomisch
            geformten Geräten gehört auch das Thema „lästige
            Geräteeinstellungen“ der Vergangenheit an.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Geraetezirkel;
