import React, { useEffect } from "react";
import ImageGallery from "../ImageGallery";
import { useMediaQuery } from "react-responsive";

function Studio() {
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });
  const imgList = [
    "DSC7843.webp",
    "DSC7845.webp",
    "DSC7847.webp",
    "DSC7850.webp",
    "DSC7855.webp",
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div className="kurspage-container">
      <div className="kurspage-header">Unser Studio</div>
      <ImageGallery
        imgList={imgList}
        mobile={isMobile}
        extendedControlls={true}
      />
    </div>
  );
}

export default Studio;
