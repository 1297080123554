import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./assets/styles/App.css";
import Main from "./pages/Main";
import Contact from "./pages/Contact";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Kurse from "./components/sites/Kurse";
import Studio from "./components/sites/Studio";

import Mitgliedschaft from "./components/sites/Mitgliedschaft";
import SubscriptionContact from "./pages/SubscriptionContact";
import Rehabilitationsport from "./components/sites/KursUnterseiten/Rehabilitationsport";
import Gesundheitskurse from "./components/sites/KursUnterseiten/Gesundheitskurse";
import Geraetezirkel from "./components/sites/KursUnterseiten/Geraetezirkel";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="*" element={<Navigate replace to="/" />} />
            <Route path="/kurse" element={<Kurse />} />
            <Route
              path="/rehabilitationssport"
              element={<Rehabilitationsport />}
            />
            <Route path="/gesundheitskurse" element={<Gesundheitskurse />} />
            <Route path="/geraetezirkel" element={<Geraetezirkel />} />
            <Route path="/studio" element={<Studio />} />

            <Route path="/mitgliedschaft" element={<Mitgliedschaft />} />
            <Route
              path="/mitgliedschaft-beantragen"
              element={<SubscriptionContact />}
            />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/impressum" element={<Impressum />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
