import React, { useState, useRef, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import burgerMenu from "../assets/img/burgerMenu.webp";
import logo from "../assets/img/LogoEpikurGsv.webp";
import openingTimes from "../data/openingTimes.json";
import chevronUp from "../assets/img/chevron_icon_up_orange.webp";
import chevronDown from "../assets/img/chevron_icon_down_orange.webp";

function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [currentOfficeTime, setCurrentOfficeTime] = useState("");
  const [showServicePopUp, setShowServicePopUp] = useState(false);
  const refServicePopUp = useRef(null);

  useEffect(() => {
    var options = {
      weekday: "short",
      timeZone: "Europe/Berlin",
    };
    var currentWeekDay = new Date()
      .toLocaleDateString("de", options)
      .replace(".", "");

    var _currentOfficeTime = openingTimes[currentWeekDay];
    var _currentOfficeTimeFrom = _currentOfficeTime.substring(0, 4);
    var _currentOfficeTimeTill = _currentOfficeTime.substring(
      7,
      _currentOfficeTime.length
    );

    if (
      _currentOfficeTime &&
      currentWeekDay !== "Sa" &&
      currentWeekDay !== "So"
    ) {
      setCurrentOfficeTime(
        "Heute geöffnet von " +
          _currentOfficeTimeFrom +
          " bis " +
          _currentOfficeTimeTill
      );
    } else {
      setCurrentOfficeTime("Heute geschlossen");
    }
  }, [openingTimes]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e) => {
    if (refServicePopUp.current.contains(e.target)) {
      onServiceBtnClicked();
    } else {
      setShowServicePopUp(false);
    }
  };

  const onServiceBtnClicked = () => {
    setShowServicePopUp(!showServicePopUp);
  };

  const openMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    setMobileBurgerState(!showMobileMenu);
  };

  const setMobileBurgerState = (setOpen) => {
    let mobileMenu = document.querySelector(".nav-mobile");
    setBurgerMenuState(mobileMenu, setOpen);
  };

  const setBurgerMenuState = (burgerMenu, setOpen) => {
    const burgerMenuTop = burgerMenu.children[0];
    const burgerMenuCenter = burgerMenu.children[1];
    const burgerMenuBottom = burgerMenu.children[2];

    if (setOpen) {
      burgerMenuTop.setAttribute("data-type", "toX");
      burgerMenuCenter.setAttribute("data-type", "toX");
      burgerMenuBottom.setAttribute("data-type", "toX");
    } else {
      burgerMenuTop.setAttribute("data-type", "reverse");
      burgerMenuCenter.setAttribute("data-type", "reverse");
      burgerMenuBottom.setAttribute("data-type", "reverse");
    }
  };

  const onNavLinkClicked = () => {
    setShowMobileMenu(false);
    setMobileBurgerState(false);
  };

  const MobileMenu = () => {
    if (showMobileMenu) {
      return (
        <div className="nav-mobile-menu bwt-bg-wrapper">
          <div className="bwt-fg-wrapper nav-mobile-links">
            <HashLink className="nav-link" to="/" onClick={onNavLinkClicked}>
              Start
            </HashLink>

            <HashLink
              className="nav-link"
              to="/kurse"
              onClick={onNavLinkClicked}
            >
              <span>Kurse</span>
            </HashLink>

            <HashLink
              className="nav-link"
              to="/kurse#kursplan"
              onClick={onNavLinkClicked}
            >
              <span>Kursplan</span>
            </HashLink>

            <HashLink
              className="nav-link"
              to="/mitgliedschaft"
              onClick={onNavLinkClicked}
            >
              <span>Mitgliedschaft</span>
            </HashLink>

            <HashLink
              className="nav-link"
              to="/studio"
              onClick={onNavLinkClicked}
            >
              Unser Studio
            </HashLink>

            <div className="horizontal-line"></div>

            <HashLink
              className="nav-link"
              to="/#oeffnungszeiten"
              onClick={onNavLinkClicked}
            >
              <span>Öffnungszeiten</span>
            </HashLink>

            <HashLink
              className="nav-link"
              to="/kontakt"
              onClick={onNavLinkClicked}
            >
              <span>Kontakt</span>
            </HashLink>

            <HashLink
              className="btn"
              to="/mitgliedschaft"
              onClick={onNavLinkClicked}
            >
              <span>Jetzt Mitglied werden</span>
            </HashLink>
          </div>
        </div>
      );
    }
    return;
  };

  return (
    <header>
      <div className="header-container bwt-bg-wrapper">
        <div className="bwt-fg-wrapper primary-header">
          <div className="upper-nav">
            <p>{currentOfficeTime}</p>
          </div>
          <div className="nav">
            <HashLink to="/#top">
              <img className="nav-logo" src={logo} alt="logo" />
            </HashLink>
            <div className="nav-links">
              <div className="nav-extended-links">
                <HashLink className="nav-link" to="/#top">
                  Start
                </HashLink>

                <HashLink className="nav-link" to="/studio">
                  Unser Studio
                </HashLink>

                <div
                  className="nav-link-dropdown nav-link"
                  onClick={onServiceBtnClicked}
                >
                  <div className="nav-link-dropdown-btn">
                    <div
                      className="nav-link-dropdown-btn-text"
                      data-type={showServicePopUp ? "active" : ""}
                    >
                      Kurse
                    </div>
                    <img
                      src={showServicePopUp ? chevronUp : chevronDown}
                      alt="chevron-icon"
                    ></img>
                  </div>

                  <div
                    className="nav-modal"
                    data-type={showServicePopUp ? "" : "inactive"}
                    ref={refServicePopUp}
                  >
                    <HashLink
                      className="nav-modal-link"
                      to="/kurse"
                      onClick={onNavLinkClicked}
                    >
                      Kurse
                    </HashLink>
                    <HashLink
                      className="nav-modal-link"
                      to="/rehabilitationssport"
                      onClick={onNavLinkClicked}
                    >
                      Rehabilitationssport
                    </HashLink>
                    <HashLink
                      className="nav-modal-link"
                      to="/gesundheitskurse"
                      onClick={onNavLinkClicked}
                    >
                      Gesundheitskurse
                    </HashLink>
                    <HashLink
                      className="nav-modal-link"
                      to="/geraetezirkel"
                      onClick={onNavLinkClicked}
                    >
                      Gerätezirkel
                    </HashLink>
                    <HashLink
                      className="nav-modal-link"
                      to="/kurse#kursplan"
                      onClick={onNavLinkClicked}
                    >
                      Kursplan
                    </HashLink>
                  </div>
                </div>

                <HashLink className="nav-link" to="/mitgliedschaft">
                  Mitgliedschaft
                </HashLink>

                <HashLink className="btn" to="/mitgliedschaft-beantragen">
                  <span>Jetzt Mitglied werden</span>
                </HashLink>
              </div>
              {/*  <div className="nav-mobile">
                <img src={burgerMenu} alt="navMenu" onClick={openMobileMenu} />
              </div> */}

              <div className="nav-mobile" onClick={(e) => openMobileMenu()}>
                <div className="nav-burger-icon-upper nav-burger-icon"></div>
                <div className="nav-burger-icon-center nav-burger-icon"></div>
                <div className="nav-burger-icon-lower nav-burger-icon"></div>
              </div>
            </div>
          </div>
          <MobileMenu />
        </div>
      </div>
    </header>
  );
}

export default Navbar;
