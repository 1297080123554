import React, { useEffect, useState, useRef } from "react";
import { startOfWeek, endOfWeek, add, format } from "date-fns";
import chevronRight from "../../assets/img/chevron_right_black.webp";
import chevronLeft from "../../assets/img/chevron_left_black .webp";
import kursData from "../../data/kursPlan.json";
import { de } from "date-fns/locale";
import { useMediaQuery } from "react-responsive";

function Kursplan() {
  const [thisWeek, setThisWeek] = useState([]);
  const [nextWeek, setNextWeek] = useState([]);
  const [showWeekView, setShowWeekView] = useState(true);
  const [userDefinedWeekIndex, setUserDefinedWeekIndex] = useState(1);
  const [showThisWeek, setShowThisWeek] = useState(true);
  const pointerWeekDayIndex = useRef(0);
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  const getWeekDaysFromFirstLastDay = (firstDay, lastDay) => {
    const week = [];
    for (let i = 0; i < 7; i++) {
      var _day = add(firstDay, { days: i });
      if (_day !== lastDay) {
        week.push(_day);
      }
    }
    return week;
  };

  useEffect(() => {
    var curr = new Date();
    const firstday = startOfWeek(curr, { weekStartsOn: 1 });
    const lastday = endOfWeek(curr, { weekStartsOn: 1 });
    setThisWeek(getWeekDaysFromFirstLastDay(firstday, lastday));

    var currNextWeek = add(curr, {
      days: 7,
    });
    const nextWeekFirstDay = startOfWeek(currNextWeek, { weekStartsOn: 1 });
    const nextWeekLastDay = endOfWeek(currNextWeek, { weekStartsOn: 1 });
    setNextWeek(getWeekDaysFromFirstLastDay(nextWeekFirstDay, nextWeekLastDay));

    pointerWeekDayIndex.current = format(curr, "i");
    setUserDefinedWeekIndex(format(curr, "i"));

    if (isMobile) {
      setShowWeekView(false);
    }
  }, [isMobile]);

  const handleSwitchWeek = (e) => {
    setShowThisWeek(!showThisWeek);
    var _switchComponent = e.target.parentElement;
    if (showThisWeek) {
      _switchComponent.setAttribute("data-type", "nextWeek");
    } else {
      _switchComponent.removeAttribute("data-type");
    }
  };

  const handleSwitchDay = (e) => {
    var weekDayList = e.target.parentElement.parentElement;
    var parent = e.target.parentElement;
    var dayIndex = parent.getAttribute("data-index");

    if (weekDayList.className === "kurs-plan-week-control") {
      if (userDefinedWeekIndex !== dayIndex) {
        var currentActiveWeekDay =
          weekDayList.children[userDefinedWeekIndex - 1];
        currentActiveWeekDay.children[1].removeAttribute("data-type");
        parent.children[1].setAttribute("data-type", "active");
        setUserDefinedWeekIndex(dayIndex);
      }
    }
  };

  const getCurrentWeekData = () => {
    if (showThisWeek) {
      return thisWeek;
    } else {
      return nextWeek;
    }
  };

  const renderWeekControl = () => {
    var weekData = getCurrentWeekData();

    return (
      <>
        {weekData.map((weekDay) => {
          var dayName = format(weekDay, "iiiiii", { locale: de });
          var date = format(weekDay, "dd");
          var weekIndex = format(weekDay, "i");

          return (
            <div
              className="kurs-plan-week-control-weekday"
              data-index={weekIndex}
              onClick={(e) => handleSwitchDay(e)}
            >
              <div className="kurs-plan-week-control-weekday-dayName">
                {dayName}
              </div>
              <div
                className="kurs-plan-week-control-weekday-date"
                data-type={
                  pointerWeekDayIndex.current === weekIndex ? "active" : ""
                }
              >
                {date}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const renderWeekDayContent = () => {
    var weekData = getCurrentWeekData();
    var currentDate = weekData[userDefinedWeekIndex - 1];

    if (currentDate) {
      var selectedShortDay = format(currentDate, "EEEEEE");
      var selectedLongDay = currentDate.toLocaleDateString("de-DE", {
        weekday: "long",
      });
      var selectedDate = currentDate.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      return (
        <>
          <div className="kurs-plan-week-current-date">
            {selectedLongDay + " " + selectedDate}
          </div>
          <div className="kurs-plan-week-content-list">
            {selectedShortDay !== "Sa" && selectedShortDay !== "Su" ? (
              <>
                {kursData[selectedShortDay].map((entry) => {
                  return (
                    <div
                      className="kurs-plan-week-content-wrapper"
                      data-type={entry["type"]}
                    >
                      <div className="kurs-plan-week-content-title">
                        {entry["title"]}
                      </div>
                      <div className="kurs-plan-week-content-time">
                        {entry["times"]}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div
                  className="kurs-plan-week-content-wrapper"
                  data-type="error"
                >
                  <div className="kurs-plan-week-content-error">
                    Heute bieten wir keine Kurse an.
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      );
    } else {
      return (
        <div className="kurs-plan-week-content-wrapper" data-type="error">
          <div className="kurs-plan-week-content-error">
            Es ist ein Fehler aufgetreten, aktualisieren Sie bitte die Seite.
          </div>
        </div>
      );
    }
  };

  const renderWeekViewSwitch = () => {
    var weekData = getCurrentWeekData();
    var firstWeekDay = "";
    var lastWeekDay = "";
    if (weekData.length > 0) {
      firstWeekDay = format(weekData[0], "dd MMMM", { locale: de });
      lastWeekDay = format(weekData[6], "dd MMMM", { locale: de });
    }

    const handleOnClick = (_showThisWeek) => {
      setShowThisWeek(_showThisWeek);
    };

    return (
      <div className="kurs-plan-weekview-switch">
        <div
          className="kurs-plan-weekview-switch-icon"
          onClick={(e) => handleOnClick(true)}
        >
          <img src={chevronLeft} alt="chevron"></img>
        </div>
        <p className="kurs-plan-weekview-switch-date-container">
          {firstWeekDay + " - " + lastWeekDay}
        </p>
        <div
          className="kurs-plan-weekview-switch-icon"
          onClick={(e) => handleOnClick(false)}
        >
          <img src={chevronRight} alt="chevron"></img>
        </div>
      </div>
    );
  };

  const renderWeekViewContent = () => {
    var weekData = getCurrentWeekData();

    return (
      <>
        <div className="kurs-plan-weekview-table-header">
          {weekData.map((weekDay, i) => {
            if (i < 5) {
              var dayName = format(weekDay, "iiiiii", { locale: de });
              var dateName = format(weekDay, "d MMMM", { locale: de });

              return (
                <div className="kurs-plan-weekview-table-header-text">
                  {dayName + ", " + dateName}
                </div>
              );
            }
            return <></>;
          })}
        </div>
        <div className="kurs-plan-weekview-table-content">
          {weekData.map((weekDay, i) => {
            if (i < 5) {
              var weekDayShortDay = format(weekDay, "EEEEEE");
              return (
                <div className="kurs-plan-weekview-table-column">
                  {kursData[weekDayShortDay].map((entry) => {
                    return (
                      <div
                        className="kurs-plan-weekview-table-content-item"
                        data-type={entry["type"]}
                      >
                        <div className="kurs-plan-weekview-table-content-item-title">
                          {entry["title"]}
                        </div>
                        <div className="kurs-plan-weekview-table-content-item-time">
                          {entry["times"]}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            }
            return <></>;
          })}
        </div>
      </>
    );
  };

  const renderIndexController = () => {
    const handleOnClick = (e) => {
      var parentElement = e.target.parentNode;
      var index = e.target.getAttribute("data-value");

      for (var i = 0; i < parentElement.children.length; i++) {
        parentElement.children[i].removeAttribute("data-type");
      }
      e.target.setAttribute("data-type", "active");

      switch (index) {
        case "1":
          setShowWeekView(false);
          break;
        case "2":
          setShowWeekView(true);
          break;
        default:
          setShowWeekView(true);
          break;
      }
    };

    return (
      <div className="kurs-plan-index-controller">
        <div
          className="kurs-plan-index-controller-item"
          data-value="1"
          onClick={(e) => handleOnClick(e)}
        >
          Tag
        </div>
        <div
          className="kurs-plan-index-controller-item"
          data-type="active"
          data-value="2"
          onClick={(e) => handleOnClick(e)}
        >
          Woche
        </div>
      </div>
    );
  };

  return (
    <>
      {isMobile ? <></> : renderIndexController()}

      {/* Day View */}
      {showWeekView ? (
        <></>
      ) : (
        <div className="kurs-plan-dayview">
          <div
            className="kurs-plan-control-week-switch"
            onClick={(e) => handleSwitchWeek(e)}
          >
            {showThisWeek ? (
              <>
                <div className="kurs-plan-control-switch-header">
                  Nächste Woche
                </div>
                <img
                  className="kurs-plan-control-switch-icon"
                  src={chevronRight}
                  alt="chevron"
                ></img>
              </>
            ) : (
              <>
                <img
                  className="kurs-plan-control-switch-icon"
                  src={chevronLeft}
                  alt="chevron"
                ></img>
                <div className="kurs-plan-control-switch-header">
                  Aktuelle Woche
                </div>
              </>
            )}
          </div>
          <div className="kurs-plan-week-control">{renderWeekControl()}</div>
          <div className="kurs-plan-week-content">{renderWeekDayContent()}</div>
        </div>
      )}

      {/* Weekly View */}
      {!isMobile && showWeekView ? (
        <div className="kurs-plan-weekview">
          <div className="kurs-plan-weekview-control">
            {renderWeekViewSwitch()}
          </div>
          <div className="kurs-plan-weekview-table">
            {renderWeekViewContent()}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="kurs-plan-color-scheme">
        <div className="kurs-plan-color-item">
          <div className="kurs-plan-color-item-dot" data-type="1"></div>
          <div className="kurs-plan-color-item-title">
            Sportgruppen ohne Verordnung (offene Kurse)
          </div>
        </div>
        <div className="kurs-plan-color-item">
          <div className="kurs-plan-color-item-dot" data-type="2"></div>
          <div className="kurs-plan-color-item-title">
            Rehabilitationssport (nur mit ärztlicher Verordnung)
          </div>
        </div>
      </div>
    </>
  );
}

export default Kursplan;
