import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import SubscriptionForm from "../components/Forms/SubscriptionForm";

function SubscriptionContact() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [defaultSubType, setDefaultSubType] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlSub = urlParams.get("subscription");
    if (urlSub) {
      setDefaultSubType(urlSub);
    }
  }, []);

  const SubscriptionFormComponent = () => {
    if (showConfirmation) {
      return (
        <div className="form-submit-message">
          <p>Herzlichen Dank für ihre Mitgliedschaftsbewerbung.</p>
          <p>
            Hiermit bestätigen wir die Ankunft. Wir werden Sie so schnell wie
            möglich über die von Ihnen angegebene E-Mail kontaktieren.
          </p>
          <HashLink to="/" className="btn">
            Zurück zur Startseite
          </HashLink>
        </div>
      );
    } else {
      return (
        <SubscriptionForm
          AfterSubmitAction={setShowConfirmation}
          defaultSubType={defaultSubType}
        />
      );
    }
  };

  return (
    <section id="contact">
      <div className="kurspage-container">
        <h1 className="kurspage-header text-center">
          Mitgliedschaft beantragen
        </h1>
        <SubscriptionFormComponent />
      </div>
    </section>
  );
}

export default SubscriptionContact;
